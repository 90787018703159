import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GoogleMapReact from "google-map-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarker } from "@fortawesome/free-solid-svg-icons"

const AnyReactComponent = ({ text }) => (
  <div
    style={{
      textAlign: "center",
      opacity: ".8",
    }}
  >
    <FontAwesomeIcon icon={faMapMarker} size="3x" />
  </div>
)

const LocationPage = () => (
  <Layout>
    <SEO title="Location" />
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-4">Location</h1>
          <h2 class="subtitle">Here is how to find us!</h2>
        </div>
      </div>
    </section>
    <div class="section" style={{ "padding-top": "1rem" }}>
      <div class="columns">
        <div class="column is-1" />
        <div class="column">
          <div class="content">
            <p>
              The town of Alcobaça is 100 km north of Lisbon with easy access by
              the highways A1 or A8 and by several national roads.
            </p>
            <p>
              Silveira is 3 km south of Alcobaça on the national road N 8-6
              towards Évora de Alcobaça, Turquel, Benedita.
            </p>
            <p>
              From Silveira, it is easy to travel around and alternate visits of
              the cultural patrimony, bathes in the ocean or foot walks in the
              natural Park of the Serras de Aire and Candeeiros.
            </p>
            <h4>Caravans and motorhomes</h4>
            <p>
              We recommend that caravans and camping-cars, especially if they
              are long, to enter the camping with the gate to their right. When
              arriving from Alcobaça the entrance is on your left, continue on
              to the first roundabout (1 km) and turn around.
            </p>
            <p><strong>The recepetion is open between 8:30 and 20:30.</strong></p>
            <br></br>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="card-image">
              <div style={{ height: "50vh", width: "100%" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyBD0DW9vSvjU7XrcHvhuRG2OQmTGmJonqg",
                  }}
                  defaultCenter={{
                    lat: 39.527153,
                    lng: -8.964215,
                  }}
                  defaultZoom={12}
                >
                  <AnyReactComponent
                    lat={39.527153}
                    lng={-8.964215}
                    text="Camping Silveira"
                  />
                </GoogleMapReact>
              </div>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">Address</p>
                </div>
              </div>
              <div class="content">
                <p>
                  Silveira, Capuchos <br />
                  2460-479 Alcobaça <br />
                  Portugal
                </p>
                <a
                  href="https://goo.gl/maps/uwZkUvcQJRShSh7G6"
                  class="button is-primary"
                >
                  Click here for Google Maps location
                </a>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div class="column is-1" />
      </div>
    </div>
  </Layout>
)

export default LocationPage

